import React, { useEffect, useState } from 'react';

const App = () => {
  const [user, setUser] = useState(null);
  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);

  useEffect(() => {
    // Проверяем, доступен ли объект window.Telegram и WebApp API
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      tg.ready(); // Инициализация WebApp API
      setUser(tg.initDataUnsafe?.user); // Получаем данные о пользователе
      setIsTelegramWebApp(true); // Приложение запущено через Telegram WebApp
    } else {
      console.log("Приложение запущено вне Telegram WebApp");
    }
  }, []);

  return (
    <div style={styles.container}>
      {isTelegramWebApp ? (
        user ? (
          <div>
            <h1>Привет, {user.first_name}</h1>
            <img src={user.photo_url} alt="Avatar" />
          </div>
        ) : (
          <p>Загрузка данных пользователя...</p>
        )
      ) : (
        <p>Приложение запущено вне Telegram WebApp Пошел на хуй, пидр любопытный!</p>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
  }
};

export default App;